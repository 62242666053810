<template>
  <mdc-button
    v-if="$store.state.currentTime"
    ripple
    class="w-full"
    @click.native="showDialog"
    v-text="`${buttonTime}*`"
  />
</template>

<script>
import { alertDialog } from '@/utils/dialog';
import { DateTime } from 'luxon';
import MdcButton from '@/components/mdc/button.vue';

export default {
  components: {
    MdcButton,
  },

  computed: {
    buttonTime() {
      return this.$store.state.currentTime.toLocaleString(
        DateTime.DATETIME_SHORT
      );
    },
  },

  methods: {
    showDialog() {
      alertDialog(
        `${this.$store.state.currentTime.toLocaleString(
          DateTime.DATETIME_HUGE
        )} (${this.$store.state.currentTime.offsetNameShort})`,
        'This is the time of your most recent activity, converted to your timezone.'
      );
    },
  },
};
</script>
