<template>
  <div class="h-full">
    <div
      :class="{ 'mdc-linear-progress--closed': !$store.state.loading }"
      class="mdc-linear-progress mdc-linear-progress--indeterminate z-50 fixed"
      role="progressbar"
    >
      <div class="mdc-linear-progress__buffering-dots" />
      <div class="mdc-linear-progress__buffer" />
      <div class="mdc-linear-progress__bar mdc-linear-progress__primary-bar">
        <span class="mdc-linear-progress__bar-inner" />
      </div>
      <div class="mdc-linear-progress__bar mdc-linear-progress__secondary-bar">
        <span class="mdc-linear-progress__bar-inner" />
      </div>
    </div>

    <aside
      ref="drawer"
      :class="
        windowWidth < changeDrawers
          ? 'mdc-drawer--modal'
          : 'mdc-drawer--dismissible'
      "
      class="mdc-drawer bg-gray-light"
    >
      <div class="mdc-drawer__header">
        <i
          class="
            material-icons
            absolute
            right-0
            mt-5
            bg-gray-light
            text-gray-dark
            hover:text-black hover:shadow hover:bg-white
            rounded-l
            cursor-pointer
          "
          @click="clickDrawer"
          >chevron_left</i
        >
      </div>
      <div class="mdc-drawer__content flex flex-col">
        <router-view name="sidebar" />
        <div class="flex-1" />
        <later-current-time class="" />
      </div>
    </aside>

    <div v-if="windowWidth < changeDrawers" class="mdc-drawer-scrim" />

    <div class="mdc-drawer-app-content h-full">
      <header
        v-if="selectedMenuItem"
        ref="header"
        :class="`bg-lgb-${selectedMenuItem.color}`"
        class="
          mdc-top-app-bar mdc-top-app-bar--fixed
          absolute
          z-30
          bg-gradient-to-b
        "
      >
        <div class="mdc-top-app-bar__row">
          <section
            class="
              mdc-top-app-bar__section mdc-top-app-bar__section--align-start
              flex
              justify-between
              items-center
            "
          >
            <div class="flex items-center">
              <i
                v-if="!$store.state.sidebarOpen"
                class="
                  material-icons
                  absolute
                  hidden
                  md:block
                  left-0
                  -ml-1
                  w-5
                  bg-gray-light
                  text-gray-dark
                  hover:text-black hover:bg-white hover:shadow-lg
                  shadow
                  rounded-r
                  cursor-pointer
                "
                @click="clickDrawer"
                >chevron_right</i
              >
              <i
                class="
                  material-icons
                  mdc-top-app-bar__icon--menu
                  cursor-pointer
                  p-2
                  text-white
                  rounded-full
                  hover:bg-semi-transparent
                  md:hidden
                "
                @click="clickDrawer"
                >menu</i
              >

              <mdc-menu class="ml-3">
                <template slot="trigger">
                  <i
                    class="
                      material-icons
                      mdc-top-app-bar__icon--menu
                      align-middle
                    "
                    v-text="selectedMenuItem.icon"
                  />
                  <span
                    class="pl-1 hidden md:inline-block"
                    v-html="selectedMenuItem.label"
                  />
                  <i
                    class="
                      material-icons
                      mdc-top-app-bar__icon--menu
                      align-middle
                    "
                    >keyboard_arrow_down</i
                  >
                </template>
                <template slot="list">
                  <router-link
                    to="/email"
                    class="mdc-list-item"
                    role="menuitem"
                  >
                    <i
                      class="material-icons mdc-list-item__graphic"
                      aria-hidden="true"
                      >email</i
                    >
                    <span class="mdc-list-item__text">Emails</span>
                  </router-link>
                  <div role="separator" class="mdc-list-divider" />
                  <router-link
                    to="/account"
                    class="mdc-list-item"
                    role="menuitem"
                  >
                    <i
                      class="material-icons mdc-list-item__graphic"
                      aria-hidden="true"
                      >settings</i
                    >
                    <span class="mdc-list-item__text">Account Settings</span>
                  </router-link>
                  <div role="separator" class="mdc-list-divider" />
                  <router-link to="/faq" class="mdc-list-item" role="menuitem">
                    <i
                      class="material-icons mdc-list-item__graphic"
                      aria-hidden="true"
                      >live_help</i
                    >
                    <span class="mdc-list-item__text">FAQ</span>
                  </router-link>
                  <router-link
                    to="/contact-us"
                    class="mdc-list-item"
                    role="menuitem"
                  >
                    <i
                      class="material-icons mdc-list-item__graphic"
                      aria-hidden="true"
                      >question_answer</i
                    >
                    <span class="mdc-list-item__text">Contact Us</span>
                  </router-link>
                  <div role="separator" class="mdc-list-divider" />
                  <div
                    class="mdc-list-item"
                    role="menuitem"
                    @click="$store.dispatch('logout', {})"
                    @keyup.enter="$store.dispatch('logout', {})"
                  >
                    <i
                      class="material-icons mdc-list-item__graphic"
                      aria-hidden="true"
                      >power_settings_new</i
                    >
                    <span class="mdc-list-item__text" v-text="'Sign out'" />
                  </div>
                </template>
              </mdc-menu>
            </div>

            <svg-logo class="mdc-top-app-bar__title w-48 mr-4 mt-2 p-0" />
          </section>
        </div>
      </header>

      <main
        ref="main"
        class="mdc-top-app-bar--fixed-adjust h-full flex flex-col"
      >
        <div class="flex-1">
          <router-view />
        </div>

        <footer class="bg-gray shadow-inner flex-no-shrink">
          <div class="text-xs text-center p-2">
            Copyright &copy;
            {{
              this.$store.state.currentTime
                ? this.$store.state.currentTime.year
                : new Date().getFullYear()
            }}
            Later.io All rights reserved.
            <router-link to="/terms"> Terms </router-link>
          </div>
        </footer>
      </main>
    </div>

    <mdc-snackbar
      :value="$store.state.snackbarMessage"
      @input="$store.commit('snackbarMessage', null)"
    />
  </div>
</template>

<script>
import { MDCDrawer } from '@material/drawer';
import { MDCTopAppBar } from '@material/top-app-bar/index';
import LaterCurrentTime from '@/components/current-time.vue';
import MdcMenu from '@/components/mdc/menu.vue';
import MdcSnackbar from '@/components/mdc/snackbar.vue';
import SvgLogo from '@/svg/logo.svg';

export default {
  components: {
    LaterCurrentTime,
    MdcMenu,
    MdcSnackbar,
    SvgLogo,
  },
  data: () => ({
    mdcDrawer: null,
    mdcTopAppBar: null,
    mdcMenuRight: null,
    windowWidth: null,
    changeDrawers: 768,
  }),
  computed: {
    selectedMenuItem() {
      if (this.$route.path.startsWith('/email'))
        return { label: 'Emails', color: 'primary', icon: 'email' };
      if (this.$route.path.startsWith('/account'))
        return { label: 'Account Settings', color: 'black', icon: 'settings' };
      return {
        label: this.$route.meta.title,
        color: 'secondary',
        icon: this.$route.meta.icon,
      };
    },
  },
  mounted() {
    this.mdcTopAppBar = new MDCTopAppBar(this.$refs.header);
    this.mdcTopAppBar.setScrollTarget(this.$refs.main);
    document.body.addEventListener('MDCDrawer:closed', this.makeSureClosed);

    this.windowWidth = window.innerWidth;
    this.$nextTick(() => {
      this.mdcDrawer = new MDCDrawer(this.$refs.drawer);
      this.mdcDrawer.open = this.$store.state.sidebarOpen;
    });

    window.onresize = (event) => {
      if (
        (this.windowWidth >= this.changeDrawers &&
          event.target.innerWidth < this.changeDrawers) ||
        (this.windowWidth < this.changeDrawers &&
          event.target.innerWidth >= this.changeDrawers)
      ) {
        this.mdcDrawer.destroy();
        this.windowWidth = event.target.innerWidth;
        this.$nextTick(() => {
          this.mdcDrawer = new MDCDrawer(this.$refs.drawer);
          this.mdcDrawer.open = this.$store.state.sidebarOpen;
        });
      }
    };
  },
  beforeDestroy() {
    this.mdcTopAppBar.destroy();
    this.mdcDrawer.destroy();
    document.body.removeEventListener('MDCDrawer:closed', this.makeSureClosed);
  },
  methods: {
    clickDrawer() {
      const drawerOpen = !this.mdcDrawer.open;
      this.mdcDrawer.open = drawerOpen;
      this.$store.commit('sidebarOpen', drawerOpen);
    },
    makeSureClosed() {
      if (this.$store.state.sidebarOpen)
        this.$store.commit('sidebarOpen', false);
    },
  },
};
</script>
